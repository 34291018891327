body {
  --justin-joyce-purple: #7851a9;
  --justin-joyce-black: #282c35;
  --justin-joyce-white: #e2e2e2;
  background-color: var(--justin-joyce-black);
  color: var(--justin-joyce-white);
  display: block;
  box-sizing: border-box;
}

h1 {
  font-weight: 400;
}

a {
  color: var(--justin-joyce-white);
}

.text-center {
  text-align: center;
}

.plus-minus-input {
  margin-bottom: 15px;
  display: flex;
}

.plus-minus-label {
  display: block;
  margin: 10px 5px 2px;
  text-align: left;
  width: 30%;
}

.plus-minus-input input {
  color: var(--justin-joyce-purple);
  font-size: 1.5em;
  margin: 0;
  padding: 10px;
  text-align: center;
  width: 75%;
  border: none;
  border-radius: 0;
}

.plus-minus-input button {
  font-size: 2em;
  background: white;
  border: none;
  border-radius: 0;
  color: var(--justin-joyce-purple);
  cursor: pointer;
  padding: 0 25px;
}

.input-wrapper {
  display: flex;
  width: 65%;
}


/* Remove the default up and down arrows from the number input */
.plus-minus-input input::-webkit-outer-spin-button,
.plus-minus-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.flex-wrap {
  flex-flow: row wrap;
}

.width-100 {
  width: 100%;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.configuration-panel {
  width: 45%;
}

.results-card-container {
  width: 51%;
}

.results-card {
  justify-content: center;
  width: 100%;
}

.results-panel {
  width: 50%;
}

.dollar-amount {
  font-size: 3em;
  margin-top: 15px;
}

@media (min-width: 1024px) {
  .App {
    margin: 0 15%;
  }

  .plus-minus-input {
    margin-top: 15px;
  }

  .configuration-panel {
    /* to line up with results */
    margin-top: 1.5em;
  }
}

@media (max-width: 1024px) {
  .results-panel {
    width: 100%;
    margin-bottom: 10px;
  }

  .dollar-amount {
    font-size: 2.5em;
  }

  .results-panel .flex {
    justify-content: space-evenly;
  }

  .results-card-container {
    width: 40%;
    text-align: center;
  }

  .results-card-container:last-of-type {
    width: 100%;
  }

  .configuration-panel {
    width: 100%;
  }

  .plus-minus-input {
    justify-content: space-between;
    text-align: center;
    flex-flow: row wrap;
  }

  .plus-minus-label {
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
    text-align: center;
    font-size: 1.5em;
  }

  .input-wrapper {
    width: 90%;
    margin: auto;
  }

  h1 {
    margin-bottom: 5px;
  }
}